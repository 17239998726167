import React from 'react';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Offers from '../components/Offers';
import ServicesSection from '../components/ServicesSection';
import VideoGallery from '../components/Videos';

export default function Services() {
  return (
    <div className='service-style'>
      <Navbar/>
      <Offers/>
      <ServicesSection/>
      <VideoGallery/>
      <Footer/>
    </div>
  )
}
