import React from 'react';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Projects from '../components/Projects';

export default function ProjectPage() {
  return (
    <div>
        <Navbar/>
        <Projects/>
        <Footer/>
    </div>
  )
}
