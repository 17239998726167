import React from 'react';

const ServicesSection = () => {
  return (
    <>
    <div className="split-section">
      <div className="text-content">
        <h1>Custom Home Construction</h1>
        <p>
        Our team specializes in crafting exceptional custom homes tailored to your vision. From the initial 
        concept to the final touches, we are committed to delivering superior craftsmanship and attention to detail.
        </p>
      </div>

      <div className="image-section">
        <img 
          src="https://res.cloudinary.com/dtqrzl86y/image/upload/v1739289891/UltraSmart%20Homes/c837a6_9045f280be72474491617fe62485c878_mv2_qvyh8h.webp" 
          alt="Illustration"
        />
      </div>
    </div>

    <div className="split-section">
      <div className="image-section">
        <img 
          src="https://res.cloudinary.com/dtqrzl86y/image/upload/v1739289891/UltraSmart%20Homes/c837a6_567d2b6e76584c129e68e1179ec80fa2_mv2_vty26r.webp"
          alt="Illustration"
        />
      </div>

      <div className="text-content">
        <h1>Architectural and Structural Design</h1>
        <p>
        At Ultrasmart Constructions, we offer comprehensive architectural and structural design services 
        to ensure that every aspect of your home is meticulously planned and executed with precision.
        </p>
      </div>
    </div>

    <div className="split-section">
      <div className="text-content">
        <h1>Bill of Quantity</h1>
        <p>
        Utilizing advanced techniques and industry expertise, we provide accurate and detailed bill 
        of quantity services to streamline the cost estimation process for your home construction project.
        </p>
      </div>

      <div className="image-section">
        <img 
          src="https://res.cloudinary.com/dtqrzl86y/image/upload/v1739289890/UltraSmart%20Homes/c837a6_e02e4a7aa8d445f585c434ed55b60df8_mv2_u0ie0i.webp" 
          alt="Illustration"
        />
      </div>
    </div>

    <div className="split-section">
      <div className="image-section">
        <img 
          src="https://res.cloudinary.com/dtqrzl86y/image/upload/v1739289892/UltraSmart%20Homes/11062b_cdc62dad3d5945fb9c7aedaaadf3fbbf_mv2_y3dzo0.webp" 
          alt="Illustration"
        />
      </div>

      <div className="text-content">
        <h1>Consulting and Planning</h1>
        <p>
        Our seasoned project management professionals oversee every aspect of the construction process, ensuring 
        seamless coordination, timely completion, and adherence to the highest standards of quality.
        </p>
      </div>
    </div>

    <div className="split-section">
      <div className="text-content">
        <h1>Project Management</h1>
        <p>
        Embark on your home construction journey with confidence, guided by our expert consultation and planning services. We provide 
        valuable insights and strategic guidance to help you make informed decisions at every stage.
        </p>
      </div>

      <div className="image-section">
        <img 
          src="https://res.cloudinary.com/dtqrzl86y/image/upload/v1739289893/UltraSmart%20Homes/c837a6_273511ee20ef47c99f6aeeeb89831157_mv2_nysvog.webp"
          alt="Illustration"
        />
      </div>
    </div>

    </>
  );
};

export default ServicesSection;