import './styles/nav/Nav.css';
import './styles/footer/Footer.css';
import './styles/hero/HeroPage.css';
import './styles/about/About.css';
import './styles/projectx/Projects.css';
import './styles/contact/Contacts.css';
import './styles/offers/Offers.css';
import './styles/offers/Videos.css';
import './App.css';

import React from 'react';

import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import AboutPage from './pages/AboutPage';
import Contact from './pages/ContactPage';
import Home from './pages/Home';
import ProjectPage from './pages/ProjectPage';
import Services from './pages/ServicesPage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route index element={<Home />} />
        <Route path="/aboutpage" element={<AboutPage />} />
        <Route path="/services" element={<Services/>} />
        <Route path="/projectpage" element={<ProjectPage />} />
        {/* <Route path="/projects" element={<Careers />} /> */}
        <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
