import React from 'react';

const Offers = () => {
  return (
    <div className="services-container">
      <p className="services-subtitle">Bridging the Worlds of Design and Construction for Exceptional Homes</p>
      <h2 className="services-title">We Offer a Range of Services to Meet Your Needs</h2>
      
      <div className="services-grid">
        <div className="service-item">
          <div className="service-icon">🏡</div>
          <h3 className="service-title">Custom Home Construction</h3>
        </div>
        <div className="service-item">
          <div className="service-icon">🏡</div>
          <h3 className="service-title">Architectural and Structural Designs</h3>
        </div>

        <div className="service-item">
          <div className="service-icon">🏢</div>
          <h3 className="service-title">Bill of Quantity</h3>
        </div>
        
        <div className="service-item">
          <div className="service-icon">🔧</div>
          <h3 className="service-title">Consultation and Planning</h3>
        </div>
        
        <div className="service-item">
          <div className="service-icon">🧱</div>
          <h3 className="service-title">Project Management</h3>
        </div>

      </div>
    </div>
  );
};

export default Offers;
