import React, { useState } from 'react';

import { Link } from 'react-router-dom';

const logo = 'https://res.cloudinary.com/dtqrzl86y/image/upload/v1719320004/UltraSmart%20Homes/logo_a9a1lc.jpg'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className='nav-Bar'>
      <div className="nav-header">
        <img src={logo} alt="logo" loading='lazy' className='logo' />
        <div className="hamburger" onClick={toggleMenu}>
          <span className={isOpen ? "bar open" : "bar"}></span>
          <span className={isOpen ? "bar open" : "bar"}></span>
          <span className={isOpen ? "bar open" : "bar"}></span>
        </div>
      </div>

      {/* <Link to="/about">About</Link> */}

      <ul className={isOpen ? "nav-links open" : "nav-links"}>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/aboutpage">About</Link></li> 
        <li><Link to="/services">Services</Link></li>
        <li><Link to="/projectpage">Projects</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        {/* <li><Link to="/contact">Contact</Link></li> */}
      </ul>
    </nav>
  );
};

export default Navbar;