import React from 'react';

import Facebook from '../assets/image/png/faceB.png';
import Instagram from '../assets/image/png/instagram.png';
import LinkedIn from '../assets/image/png/linkee.png';
import Tiktok from '../assets/image/png/tiktok.png';
import X from '../assets/image/png/tweet.png';
import YouTube from '../assets/image/png/youtube.png';

export default function Footer() {
  let presentDate = new Date();
  let year = presentDate.getFullYear();

  return (
    <footer>      
      <div className='foot-wrapper'>
        <div className='line'></div>
        <div className='foot-one'>
          <div className='address'>
            <h1>Address</h1>
            <p>A15, Digiforte City, Abuja.</p>
          </div>

          <div className='phone'>
            <h1>Phone</h1>
            <p>+2349030853690</p>
          </div>

          <div className='email'>
            <h1>Email</h1>
            <p><a href="mailto:ultrasmartconstructions@gmail.com">ultrasmartconstructions@gmail.com</a></p>
          </div>  
        </div>

        <div className='foot-two'>
          <div className='go-to'>
            <h1>Go To</h1>
            <p>Home</p>
            <p>About</p>
            <p>Properties</p>
            <p>Contact</p>
          </div>

          <div className='support'>
            <h1>Support</h1>
            <p>Privacy Policy</p>
            <p>Help Centre</p>
          </div>

          <div className='media'>
            <h1>Social Media</h1>

            <div className='media-icon'>
              <a href="https://www.facebook.com"><img src={Facebook} alt="FB"/></a>
              <a href="https://www.instagram.com/ultrasmart_constructions_ltd?igsh=MXB4Y2MzOGd5d3NnMQ%3D%3D"><img src={Instagram} alt="IG" style={{ width: '20px', height: '20px'}}/></a>
              <a href="https://www.linkedin.com"><img src={LinkedIn} alt="LkIn"/></a>
              <a href="https://wwww.tiktok.com"><img src={Tiktok} alt="TikTok"/></a>
              <a href="https://www.x.com"><img src={X} alt="X" style={{ width: '20px', height: '20px'}}/></a>
              <a href="https://www.youtube.com"><img src={YouTube} alt="YT" style={{ width: '20px', height: '20px'}}/></a>  
            </div>

          </div>
        </div>

      </div>

        <div>
          <p className="copy">
            Copyrights &copy;{year} UltraSmart Constructions Limited.
          </p>
        </div>
        
    </footer>
  );
};

