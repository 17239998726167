import React from 'react';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

const Contact = () => {
  return (
    <>
    <Navbar/>
    <h5 className="section-title">CONTACT US</h5>
    <div className="contact-container">
      <div className="right-section">
        <form className="form">
          <div className="row">
            <input type="text" placeholder="First Name" className="input" />
            <input type="text" placeholder="Last Name" className="input" />
          </div>
          <input type="email" placeholder="Email" className="input" />
          <input type="text" placeholder="Phone Number" className="input" />
          <textarea placeholder="Leave a message here!" className="textarea"></textarea>
          <button type="submit" className="submit-button">Submit Form</button>
        </form>
      </div>

      <div className="left-section">
        {/* <h5 className="section-title">CONTACT US</h5> */}
        <p className="description">
        Feel free to contact us with any questions or suggestions! Our team is always here to assist you at any time.
        </p>

        <div className="contact-options">
          <div className="contact-option">
            <div className="icon-wrapper">
              <span role="img" aria-label="phone" className="icon">📞</span>
            </div>
            <div>
              <h3>Phone</h3>
              <p>+234-903-085-3690</p>
            </div>
          </div>

          <div className="contact-option">
            <div className="icon-wrapper">
              <span role="img" aria-label="email" className="icon">✉️</span>
            </div>
            <div className='msg'>
              <h3>Send Message</h3>
              <p><a href="mailto:ultrasmartconstructions@gmail.com">ultrasmartconstructions@gmail.com</a></p>
              {/* <p><a href="mailto:ultrasmartconstructions@gmail.com">ultrasmartconstructions@gmail.com</a></p>
              <p><a href="mailto:ultrasmartconstructions@gmail.com">ultrasmartconstructions@gmail.com</a></p> */}
            </div>
          </div>
        </div>

      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Contact;

